<template>
  <section id="final-users">
    <b-card>
      <b-card-header>
        <b-card-title>
          <h1>{{ $t("finalUsers.title") }}</h1>
          <h5 class="text-primary">
            {{ $t("finalUsers.subtitle") }}
          </h5>
        </b-card-title>
        <div>
          <b-button
            variant="danger"
            size="sm"
            @click="back()"
          >
            {{
              $t("dataGeneric.goBack")
            }}
          </b-button>
          <b-button
            class="ml-1"
            variant="success"
            size="sm"
            :disabled="selected == null"
            @click="seleccionar"
          >
            {{ $t('dataGeneric.save') }}
          </b-button>
        </div>
      </b-card-header>
      <b-row class="mb-2">
        <b-col>
          <b-form-input
            v-model="search"
            :placeholder="$t('dataGeneric.search')"
            @change="searching"
          />
        </b-col>
        <b-col
          md="1"
          style="text-align: right;"
        >
          <b-button
            variant="primary"
            @click="searching"
          >
            {{ $t('Search') }}
          </b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-table
            ref="refUserListTable"
            :items="fetchUsers"
            :fields="[
              { key: 'username', sortable: true, label: $t('firstName') },
              { key: 'email', sortable: true },
              { key: 'isActive', sortable: true, label: $t('dataGeneric.activeQ') },
            ]"
            hover
            :busy="isBusy"
            :select-mode="'single'"
            responsive="sm"
            selectable
            show-empty
            :empty-text="$t('emptyText')"
            primary-key="id"
            @row-selected="onRowSelected"
          >
            <!-- Column: User -->
            <template #table-busy>
              <div
                class="text-center text-danger my-2"
              >
                <b-spinner class="align-middle mr-1" />
                <strong>{{ $t('load') }}...</strong>
              </div>
            </template>
            <template #cell(username)="data">
              <b-media vertical-align="center">
                <template #aside>
                  <b-avatar
                    size="32"
                    :text="avatarText(data.item.node.firstName)"
                    variant="light-success"
                  />
                </template>
                <span class="font-weight-bold d-block text-nowrap">
                  {{ data.item.node.firstName }} {{ data.item.node.lastName }}
                </span>

                <span class="font-weight-bold d-block text-nowrap">
                  <small class="text-muted">@{{ cleanUsername(data.item.node.username) }}</small>
                </span>
              </b-media>
            </template>

            <!-- Column: Email -->
            <template #cell(email)="data">
              <div class="text-nowrap">
                <feather-icon
                  icon="MailIcon"
                  size="18"
                  class="mr-50"
                />
                <span class="align-text-top">{{ data.item.node.email }}</span>
              </div>
            </template>

            <!-- Column: Status -->
            <template #cell(isActive)="data">
              <b-badge
                pill
                :variant="`light-${data.item.node.isActive? 'success' : 'secondary'}`
                "
                class="text-capitalize"
              >
                <feather-icon
                  :icon="data.item.node.isActive ? 'CheckIcon' : 'XIcon'"
                  size="18"
                  class="mr-50"
                />
                {{
                  data.item.node.isActive
                    ? $t("dataGeneric.active")
                    : $t("dataGeneric.inactive")
                }}
              </b-badge>
            </template>
          </b-table>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-pagination
            v-if="rows > perPage"
            v-model="currentPage"
            :total-rows="rows"
            :per-page="perPage"
            size="lg"
            @input="getData"
          />
        </b-col>
      </b-row>
    </b-card>
  </section>
</template>
<script>
import {
  BTable,
  BRow,
  BCol,
  BCard,
  BCardHeader,
  BFormInput,
  BPagination,
  BCardTitle,
  BButton,
  BSpinner,
  BMedia,
  BAvatar,
  BBadge,
} from 'bootstrap-vue'
import axios from '@axios'
import { messageError, cleanUsername } from '@/store/functions'
import { getUserData } from '@/auth/utils'
import { avatarText } from '@core/utils/filter'

export default {
  components: {
    BTable,
    BRow,
    BFormInput,
    BCol,
    BCard,
    BSpinner,
    BCardHeader,
    BPagination,
    BCardTitle,
    BButton,
    BMedia,
    BAvatar,
    BBadge,
  },
  props: {
    platformId: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      userData: getUserData(),
      fetchUsers: [],
      selected: null,
      currentPage: 1,
      perPage: 14,
      search: '',
      avatarText,
      rows: 0,
      isBusy: false,
    }
  },
  mounted() {
    this.getData()
  },

  methods: {
    onRowSelected(items) {
      if (items[0]) {
        this.selected = items[0].node.id
        // this.$emit('finalUser', this.selected)
      } else {
        this.selected = null
      }
    },
    back() {
      this.$emit('back')
    },
    searching() {
      this.currentPage = 1
      this.getData()
    },
    getData() {
      this.isBusy = true
      axios.post('', {
        query:
              `query($client: ID,$username: String){
                allVusers(
                  first:${this.perPage},
                  offset:${this.perPage * (this.currentPage - 1)},  
                  client:$client,username:$username
                  ) {
                  totalCount
                  edgeCount
                  edges {
                    node {
                      id
                      username
                      firstName
                      lastName
                      email
                      isActive
                      isLegalWarning
                      isAcceptAds
                    }
                  }
                }
              }
            `,
        variables: {
          client: this.platformId,
          username: this.search,
        },
      }).then(res => {
        messageError(res, this)
        this.isBusy = false
        this.rows = res.data.data.allVusers.totalCount

        this.fetchUsers = res.data.data.allVusers.edges
      }).catch(() => {
        this.isBusy = false
      })
    },
    seleccionar() {
      this.$emit('finalUser', this.selected)
    },
    cleanUsername,
  },
}
</script>
